export const dataSectionTypes: { value: string; label: string }[] = [
  {
    value: 'radio',
    label: 'Radio Button'
  },
  {
    value: 'select',
    label: 'Select'
  },
  {
    value: 'checkbox',
    label: 'Checkbox'
  },
  {
    value: 'text',
    label: 'Text field'
  },
  {
    value: 'textarea',
    label: 'Textarea'
  }
]

export const getDataSectionLabelFromValue = (value: string) => {
  return dataSectionTypes.find(item => value === item.value)?.label
}
