<template>

  <v-dialog
    :width="modeType==='edit'?600:900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

        <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New Data Section</span>
          <span v-if="modeType=='edit'">Edit Data Section</span>
        </span>
          <span v-if="modeType=='edit'"><span class="font-weight-black">{{ itemTypeDataSection.name }}</span></span>
        </v-card-title>

        <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
        <v-card-text>
          <v-row>
            <v-col>

              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Name"
                    v-model="itemSectionName"
                    :rules="rules.nameRules"
                    required
                    class="text-caption"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="itemSectionDescription"
                    :rules="rules.descRules"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="7" v-if="modeType==='create'">
              <v-row>
                <v-col>
                  <v-select
                    :items="dataSectionTypes"
                    label="Component"
                    v-model="itemComponentType"
                    :item-value="item => item.value"
                    :item-text="item => item.label"
                    :rules="rules.componentTypeRules"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col  v-if="itemComponentType==='radio' || itemComponentType==='select' || itemComponentType==='checkbox'">
                  <v-text-field
                    outlined
                    label="Option"
                    v-model="optionValue"
                    hide-details
                    class="text-caption"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="d-flex align-center justify-end"
                       v-if="itemComponentType==='radio' || itemComponentType==='select'"
                >
                  <v-btn
                    depressed
                    class="white--text"
                    color="orange lighten-2"
                    @click="addOption"
                  >
                    Add Option
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="itemComponentType==='checkbox'">
                <v-col>
                  <v-text-field
                    outlined
                    label="Label"
                    v-model="checkboxLabel"
                    hide-details
                    class="text-caption"
                  ></v-text-field>
                  <v-switch
                    inset
                    color="secondary"
                    label="Checked as default"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="itemComponentType==='text'">
                <v-col>
                  <v-text-field
                    outlined
                    label="Default value"
                    v-model="textValue"
                    hide-details
                    class="text-caption"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="itemComponentType==='textarea'">
                <v-col>
                  <v-text-field
                    outlined
                    label="Default value"
                    v-model="textareaValue"
                    hide-details
                    class="text-caption"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="itemComponentType==='radio' || itemComponentType==='select'">
                <v-col>
                  <v-card elevation="0" rounded="0" class="overflow-auto veryLightGrey"
                  >
                    <v-card-text>
                      <div v-if="itemComponentType==='radio'">
                        <p class="text-center mb-0" v-if="radioData.length === 0">No Options Added</p>
                        <v-simple-table
                          class="transparent"
                        >
                          <template v-slot:default>
                            <tbody>
                            <tr
                              v-for="(item,key) in radioData"
                              :key="key + Math.random()"
                            >
                              <td>
                                <v-icon color="lightGrey">
                                  mdi-drag-horizontal-variant
                                </v-icon>
                              </td>
                              <td>{{ item.label }}</td>
                              <td>
                              <span v-if="item.checked===true"
                                    class="font-weight-bold secondary--text text-uppercase text-caption">
                                Checked
                              </span>
                              </td>
                              <td class="text-right">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon depressed v-on="on" v-bind="attrs" @click="setRadioDefault(key)">
                                      <v-icon size="18">
                                        mdi-radiobox-marked
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                  Set as default checked
                                </span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon depressed v-on="on" v-bind="attrs">
                                      <v-icon size="18" color="important" @click="removeCheckedOption(key)">
                                        mdi-close
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                  Remove option
                                </span>
                                </v-tooltip>
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <div v-if="itemComponentType==='select'">
                        <p class="text-center mb-0" v-if="selectData.length === 0">No Options Added</p>
                        <v-simple-table
                          class="transparent"
                        >
                          <template v-slot:default>
                            <tbody>
                            <tr
                              v-for="(item,key) in selectData"
                              :key="key + Math.random()"
                            >
                              <td>
                                <v-icon color="lightGrey">
                                  mdi-drag-horizontal-variant
                                </v-icon>
                              </td>
                              <td>{{ item.label }}</td>
                              <td>
                              <span v-if="item.selected===true"
                                    class="font-weight-bold secondary--text text-uppercase text-caption">
                                Selected
                              </span>
                              </td>
                              <td class="text-right">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon depressed v-on="on" v-bind="attrs" @click="setSelectDefault(key)">
                                      <v-icon size="18">
                                        mdi-form-select
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                  Set as default selected
                                </span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon depressed v-on="on" v-bind="attrs" @click="removeSelectOption(key)">
                                      <v-icon size="18" color="important">
                                        mdi-close
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                  Remove option
                                </span>
                                </v-tooltip>
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
          <v-btn depressed width="130" color="secondary">
            <span v-if="modeType=='create'" @click="submit">Add</span>
            <span v-if="modeType=='edit'" @click="submit">Update</span>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { dataSectionTypes } from '@/helpers/ItemTypeDataSectionHelper'

function initialState () {
  return {
    form: {
      id: '',
      name: '',
      component_type: '',
      description: ''
    },
    optionValue: '',
    checkboxLabel: '',
    textareaValue: '',
    textValue: '',
    checkboxChecked: false,
    valid: false,
    dataSectionTypes,
    dataSectionTypeSelected: 0,
    headers: [
      {
        text: '',
        value: 'sortable',
        sortable: false
      },
      {
        text: 'Label',
        value: 'label',
        sortable: false
      },
      {
        text: 'Required',
        value: 'required',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ],
    radioData: [],
    selectData: [],
    rules: {
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      descRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 512) || 'Description must be less than 512 characters'
      ],
      componentTypeRules: [
        v => !!v || 'Component type is required'
      ]
    }
  }
}

export default Vue.extend({
  name: 'DataSectionCreateUpdate',
  props: {
    itemTypeDataSection: {},
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    },
    itemTypeId: {
      required: true
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    itemSectionName: {
      get () {
        return this.itemTypeDataSection.name
      },
      set (val) {
        this.form.name = val
      }
    },
    itemSectionDescription: {
      get () {
        return this.itemTypeDataSection.description
      },
      set (val) {
        this.form.description = val
      }
    },
    itemComponentType: {
      get () {
        return this.form.component_type
      },
      set (val) {
        this.$set(this.form, 'component_type', val)
      }
    }
  },
  methods: {
    setRadioDefault (item) {
      for (let i = 0; i < this.radioData.length; i++) {
        this.radioData[i].checked = false
      }
      this.radioData[item].checked = true
    },
    setSelectDefault (item) {
      for (let i = 0; i < this.selectData.length; i++) {
        this.selectData[i].selected = false
      }
      this.selectData[item].selected = true
    },
    addOption () {
      if (this.itemComponentType === 'radio') {
        this.radioData.push(this.newRadioOption())
      }
      if (this.itemComponentType === 'select') {
        this.selectData.push(this.newSelectOption())
      }
      this.optionValue = ''
    },
    newRadioOption () {
      const isChecked = this.radioData.length === 0
      return { value: this.optionValue, label: this.optionValue, checked: isChecked }
    },
    newSelectOption () {
      const isChecked = this.selectData.length === 0
      return { value: this.optionValue, label: this.optionValue, selected: isChecked }
    },
    removeSelectOption (key) {
      this.selectData = this.selectData.filter((_, idx) => idx !== key)
      if (this.selectData.length > 0) {
        const isObjectPresent = this.selectData.find(i => i.selected === true)
        if (!isObjectPresent) {
          this.selectData[0].selected = true
        }
      }
    },
    removeCheckedOption (key) {
      this.radioData = this.radioData.filter((_, idx) => idx !== key)
      if (this.radioData.length > 0) {
        const isObjectPresent = this.radioData.find(i => i.checked === true)
        if (!isObjectPresent) {
          this.radioData[0].checked = true
        }
      }
    },
    submit () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }

      this.saving = true

      const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/item-type-sections/' + this.itemTypeDataSection.id : '/api/item-type-sections'
      if (this.itemComponentType === 'select') {
        this.form.possible_values = JSON.stringify(this.selectData)
      }

      if (this.itemComponentType === 'radio') {
        this.form.possible_values = JSON.stringify(this.radioData)
      }

      if (this.itemComponentType === 'checkbox') {
        this.form.possible_values = JSON.stringify({ value: this.optionValue, label: this.checkboxLabel, checked: this.checkboxChecked })
      }

      if (this.itemComponentType === 'text') {
        this.form.default_value = this.textValue
      }

      if (this.itemComponentType === 'textarea') {
        this.form.default_value = this.textareaValue
      }

      this.form.item_type_id = this.itemTypeId

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then(() => {
        this.closeModal()
        this.$emit('reload', true)
      }).catch(error => {
        console.log('ERROR', error)
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      })
    },
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      Object.assign(this.$data, initialState())
      this.form = {
        item_type_id: this.itemTypeId
      }
      this.saving = false
    },
    closeModal () {
      this.show = false
    }
  },
  watch: {
    show: function (value) {
      if (value) {
        Object.assign(this.$data, initialState())
      }
      if (value === true && this.modeType === 'edit') {
        this.form.name = this.itemTypeDataSection.name
        this.form.description = this.itemTypeDataSection.description
        this.form.component_type = this.itemTypeDataSection.component_type
        this.form.item_type_id = this.itemTypeId

        if (this.form.component_type === 'select') {
          this.selectData = JSON.parse(this.itemTypeDataSection.possible_values)
        }

        if (this.form.component_type === 'radio') {
          this.radioData = JSON.parse(this.itemTypeDataSection.possible_values)
        }

        if (this.form.component_type === 'checkbox') {
          const parsedValues = JSON.parse(this.itemTypeDataSection.possible_values)
          this.optionValue = parsedValues.value
          this.checkboxLabel = parsedValues.label
          this.checkboxChecked = parsedValues.checked
        }

        if (this.form.component_type === 'text') {
          this.textValue = this.itemTypeDataSection.default_value
        }

        if (this.form.component_type === 'textarea') {
          this.textareaValue = this.itemTypeDataSection.default_value
        }
      } else if (value === true && this.modeType === 'create') {
        this.resetModal()
      }
    }
  }
})
</script>
