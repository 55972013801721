<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Types of</span>
        <span><span class="font-weight-black">{{ itemTypeDataSection.name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="closeModal"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-row>
              <v-col>
                <v-select
                  :items="dataSectionTypes"
                  label="Component"
                  v-model="dataSectionTypeSelected"
                  :item-value="item => item.value"
                  :item-text="item => item.label"
                  :rules="rules.componentTypeRules"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col  v-if="dataSectionTypeSelected==='radio' || dataSectionTypeSelected==='select' || dataSectionTypeSelected==='checkbox'">
                <v-text-field
                  outlined
                  label="Option"
                  v-model="optionValue"
                  hide-details
                  class="text-caption"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="d-flex align-center justify-end"
                     v-if="dataSectionTypeSelected==='radio' || dataSectionTypeSelected==='select'"
              >
                <v-btn
                  depressed
                  class="white--text"
                  color="orange lighten-2"
                  @click="addOption"
                >
                  Add Option
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="dataSectionTypeSelected==='checkbox'">
              <v-col>
                <v-text-field
                  outlined
                  label="Label"
                  v-model="checkboxLabel"
                  hide-details
                  class="text-caption"
                ></v-text-field>
                <v-switch
                  inset
                  color="secondary"
                  v-model="checkboxChecked"
                  label="Checked as default"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="dataSectionTypeSelected==='text'">
              <v-col>
                <v-text-field
                  outlined
                  v-model="textValue"
                  label="Default value"
                  hide-details
                  class="text-caption"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dataSectionTypeSelected==='textarea'">
              <v-col>
                <v-text-field
                  outlined
                  v-model="textareaValue"
                  label="Default value"
                  hide-details
                  class="text-caption"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dataSectionTypeSelected==='radio' || dataSectionTypeSelected==='select'">
              <v-col>
                <v-card elevation="0" rounded="0" class="overflow-auto veryLightGrey"
                >
                  <v-card-text>
                    <div v-if="dataSectionTypeSelected==='radio'">
                      <p class="text-center mb-0" v-if="radioData.length === 0">No Options Added</p>
                      <v-simple-table
                        class="transparent"
                      >
                        <template v-slot:default>
                          <tbody>
                          <tr
                            v-for="(item,key) in radioData"
                            :key="key + Math.random()"
                          >
                            <td>
                              <v-icon color="lightGrey">
                                mdi-drag-horizontal-variant
                              </v-icon>
                            </td>
                            <td>{{ item.label }}</td>
                            <td>
                              <span v-if="item.checked===true" class="font-weight-bold secondary--text text-uppercase text-caption">
                                Checked
                              </span>
                            </td>
                            <td class="text-right">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon depressed v-on="on" v-bind="attrs" @click="setRadioDefault(key)">
                                    <v-icon size="18">
                                      mdi-radiobox-marked
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Set as default checked
                                </span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon depressed v-on="on" v-bind="attrs" @click="removeCheckedOption(key)">
                                    <v-icon size="18" color="important">
                                      mdi-close
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Remove option
                                </span>
                              </v-tooltip>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                    <div v-if="dataSectionTypeSelected==='select'">
                      <p class="text-center mb-0" v-if="selectData.length === 0">No Options Added</p>
                      <v-simple-table
                        class="transparent"
                      >
                        <template v-slot:default>
                          <tbody>
                          <tr
                            v-for="(item,key) in selectData"
                            :key="item.label + Math.random()"
                          >
                            <td>
                              <v-icon color="lightGrey">
                                mdi-drag-horizontal-variant
                              </v-icon>
                            </td>
                            <td>{{ item.label }}</td>
                            <td>
                              <span v-if="item.selected===true" class="font-weight-bold secondary--text text-uppercase text-caption">
                                Selected
                              </span>
                            </td>
                            <td class="text-right">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon depressed v-on="on" v-bind="attrs" @click="setSelectDefault(key)">
                                    <v-icon size="18">
                                      mdi-form-select
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Set as default selected
                                </span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon depressed v-on="on" v-bind="attrs" @click="removeSelectOption(key)">
                                    <v-icon size="18" color="important">
                                      mdi-close
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Remove option
                                </span>
                              </v-tooltip>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="saveComponent">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import { dataSectionTypes } from '@/helpers/ItemTypeDataSectionHelper'
import axios from 'axios'

export default Vue.extend({
  name: 'DataSectionComponents',
  props: {
    itemTypeDataSection: {
      required: true,
      type: Object
    },
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    dataSectionTypes,
    dataSectionTypeSelected: 0,
    optionValue: '',
    checkboxLabel: '',
    textareaValue: '',
    textValue: '',
    checkboxChecked: false,
    headers: [
      { text: '', value: 'sortable', sortable: false },
      { text: 'Label', value: 'label', sortable: false },
      { text: 'Required', value: 'required', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    radioData: [],
    selectData: [],
    rules: {
      componentTypeRules: [
        v => !!v || 'Component type is required'
      ]
    }
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setRadioDefault (item) {
      for (let i = 0; i < this.radioData.length; i++) {
        this.radioData[i].checked = false
      }
      this.radioData[item].checked = true
    },
    setSelectDefault (item) {
      for (let i = 0; i < this.selectData.length; i++) {
        this.selectData[i].selected = false
      }
      this.selectData[item].selected = true
    },
    addOption () {
      if (this.dataSectionTypeSelected === 'radio') {
        this.radioData.push(this.newRadioOption())
      }
      if (this.dataSectionTypeSelected === 'select') {
        this.selectData.push(this.newSelectOption())
      }
      this.optionValue = ''
    },
    newRadioOption () {
      const isChecked = this.radioData.length === 0
      return { value: this.optionValue, label: this.optionValue, checked: isChecked }
    },
    newSelectOption () {
      const isChecked = this.selectData.length === 0
      return { value: this.optionValue, label: this.optionValue, selected: isChecked }
    },
    removeSelectOption (key) {
      this.selectData = this.selectData.filter((_, idx) => idx !== key)
      if (this.selectData.length > 0) {
        const isObjectPresent = this.selectData.find(i => i.selected === true)
        if (!isObjectPresent) {
          this.selectData[0].selected = true
        }
      }
    },
    removeCheckedOption (key) {
      this.radioData = this.radioData.filter((_, idx) => idx !== key)
      if (this.radioData.length > 0) {
        const isObjectPresent = this.radioData.find(i => i.checked === true)
        if (!isObjectPresent) {
          this.radioData[0].checked = true
        }
      }
    },
    saveComponent () {
      let data = ''
      if (this.dataSectionTypeSelected === 'select') {
        data = JSON.stringify(this.selectData)
      }

      if (this.dataSectionTypeSelected === 'radio') {
        data = JSON.stringify(this.radioData)
      }

      if (this.dataSectionTypeSelected === 'checkbox') {
        data = JSON.stringify({ value: this.optionValue, label: this.checkboxLabel, checked: this.checkboxChecked })
      }

      if (this.dataSectionTypeSelected === 'text') {
        data = this.textValue
      }

      if (this.dataSectionTypeSelected === 'textarea') {
        data = this.textareaValue
      }

      const form = {
        component_type: this.dataSectionTypeSelected,
        possible_values: data
      }

      console.log('form', form)

      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/item-type-sections/' + this.itemTypeDataSection.id, form)
        .then(() => {
          this.closeModal()
          this.$emit('reload', true)
        })
    },
    closeModal () {
      this.show = false
    }
  },
  watch: {
    show: function (value) {
      if (value) {
        this.dataSectionTypeSelected = this.itemTypeDataSection.component_type
        const possible_values = this.itemTypeDataSection.possible_values
        if (this.dataSectionTypeSelected === 'select') {
          this.selectData = JSON.parse(possible_values)
        }
        if (this.dataSectionTypeSelected === 'radio') {
          this.radioData = JSON.parse(possible_values)
        }
        if (this.dataSectionTypeSelected === 'checkbox') {
          const parsedValues = JSON.parse(possible_values)
          this.optionValue = parsedValues.value
          this.checkboxLabel = parsedValues.label
          this.checkboxChecked = parsedValues.checked
        }
        if (this.dataSectionTypeSelected === 'text') {
          this.textValue = this.itemTypeDataSection.default_value
        }
        if (this.dataSectionTypeSelected === 'textarea') {
          this.textareaValue = this.itemTypeDataSection.default_value
        }
      }
    }
  }
})
</script>
