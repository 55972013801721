<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'ItemTypes' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Item Types
          </v-btn>
          <h2 class="darkGrey--text">
            Data Sections from {{ getItemType }}
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Data Section
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col cols="6" offset="6">
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="sections"
            :options.sync="options"
            :loading="loading"
            :search="search"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.sortable>
              <v-icon color="lightGrey">
                mdi-drag-horizontal-variant
              </v-icon>
            </template>
            <template v-slot:item.types="{ item }">
              <span>
                {{ getDataSectionLabelFromValue(item.component_type) }}
              </span>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.restricted="{ item }">
              <v-tooltip top v-if="item.restricted !== undefined">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.restricted.length }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                <v-row>
                  <v-col>
                    <span class="custom-tooltip-title">
                      RESTRICTED TO USERS
                    </span>
                    <ul>
                      <li v-for="item in item.restricted" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip top v-if="item.status === 'pending'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-timer-sand
                  </v-icon>
                </template>
                <span>
                  Pending
                </span>
              </v-tooltip>
              <v-tooltip top v-if="item.status === 'active'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="positive"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>
                  Active
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }" >
              <div class="d-flex flex-row justify-center">

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <data-section-create-update @reload="getSections" v-model="modals.createUpdate" :modeType="modeType" :itemTypeId="itemTypeId" :itemTypeDataSection="itemTypeDataSection" ></data-section-create-update>
    <data-section-components @reload="getSections" v-model="modals.components" :itemTypeDataSection="itemTypeDataSection"></data-section-components>
    <data-section-restricted-to-users @reload="getSections" v-model="modals.restricted" :itemTypeDataSection="itemTypeDataSection"></data-section-restricted-to-users>
    <data-section-activate @reload="getSections" v-model="modals.activate" :itemTypeDataSection="itemTypeDataSection"></data-section-activate>
    <data-section-delete @reload="getSections" v-model="modals.delete" :itemTypeDataSection="itemTypeDataSection"></data-section-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import DataSectionCreateUpdate from '@/components/ItemTypes/DataSectionCreateUpdate'
import DataSectionComponents from '@/components/ItemTypes/DataSectionComponents'
import DataSectionRestrictedToUsers from '@/components/ItemTypes/DataSectionRestrictedToUsers'
import DataSectionActivate from '@/components/ItemTypes/DataSectionActivate'
import DataSectionDelete from '@/components/ItemTypes/DataSectionDelete'
import ItemType from '@/models/ItemType'
import ItemTypeSection from '@/models/ItemTypeSection'
import { dataSectionTypes, getDataSectionLabelFromValue } from '@/helpers/ItemTypeDataSectionHelper'

// import draggable from 'vuedraggable'

export default Vue.extend({
  name: 'ItemTypeDataSections',
  components: {
    Portal,
    DataSectionCreateUpdate,
    DataSectionComponents,
    DataSectionRestrictedToUsers,
    DataSectionActivate,
    DataSectionDelete
  },
  props: {
    itemTypeProp: {},
    typeId: Number
  },
  computed: {
    getItemType () {
      if (this.itemType !== undefined) {
        return this.itemType.type
      }
      return ''
    },
    dragOptions () {
      return {
        // handle: '.handle',
        animation: 500,
        ghostClass: 'ghostClass',
        dragClass: 'dragClass',
        chosenClass: 'chosenClass'
      }
    }
  },
  data: () => ({
    itemTypeId: '',
    itemTypeDataSection: {},
    options: {},
    search: '',
    loading: false,
    itemType: {
      type: ''
    },
    sections: [],
    list: [
      { id: 1, name: 'Abby', sport: 'basket' },
      { id: 2, name: 'Brooke', sport: 'foot' },
      { id: 3, name: 'Courtenay', sport: 'volley' },
      { id: 4, name: 'David', sport: 'rugby' }
    ],
    modeType: '',
    modals: {
      createUpdate: false,
      components: false,
      restricted: false,
      activate: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Item Types',
        disabled: false,
        href: ''
      },
      {
        text: 'Data Sections from ',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-form-textbox', text: 'Components', actions: 'components' },
      { icon: 'mdi-lock-open-variant-outline', text: 'Restricted To Users', actions: 'restricted' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: '', value: 'sortable' },
      { text: 'Name', value: 'name' },
      { text: 'Restricted', value: 'restricted', sortable: false, align: 'center' },
      { text: 'Component', value: 'types', sortable: false },
      { text: 'Status', value: 'status', sortable: false, align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    sectionsSample: [
      {
        name: 'Name of Data Section 1',
        description: 'Nulla eu convallis felis. Phasellus pharetra',
        types: 'Radio',
        active: true,
        pending: false,
        restricted: ['Charles Simon', 'Vee Caron', 'Rico Reis', 'Raph Leroux', 'Tony Cappozi', 'Kyler Miles', 'Sean Canlas', 'Stephen Cawley', 'Arthur da Silva', 'Miguel Reis', 'Alline Pereira']
      },
      {
        name: 'Data Section 2',
        description: 'Mauris ut ipsum sed felis dapibus auctor vitae ut velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra',
        types: 'Text field',
        active: false,
        pending: true,
        restricted: ['Stephen Cawley', 'Arthur da Silva', 'Miguel Reis', 'Alline Pereira']
      },
      {
        name: 'D Section 3',
        description: 'Integer condimentum felis sit amet purus tempus tincidunt',
        types: 'Checkbox',
        active: false,
        pending: true,
        restricted: ['Stephen Cawley']
      },
      {
        name: 'Name of Data Section 4',
        description: 'Etiam fermentum libero metus. Mauris at consequat velit, at pretium ipsum. Vivamus at ligula eu turpis auctor dapibus. Donec sed massa ipsum',
        types: 'Text field',
        active: false,
        pending: true
      }
    ]
  }),
  methods: {
    startingDrag () {
      this.dragging = true
    },
    endingDrag () {
      this.dragging = false
    },
    getDataSectionLabelFromValue,
    openModal (item, optional, dataSection) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (dataSection) this.itemTypeDataSection = dataSection
    },
    async getItemTypeFromDatabase () {
      this.itemType = await ItemType
        .where('id', this.itemTypeId)
        .first()
    },
    async getSections () {
      this.loading = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      ItemTypeSection
        .page(page)
        .where('item_type_id', this.itemTypeId)
        .orderBy(orderBy)
        .get()
        .then(response => {
          this.sections = response.data
          this.sections.forEach(elm => {
            elm.active = false
            elm.pending = false
            elm.paused = false
            elm.reserved = false
            elm[elm.status] = true

            if (elm.status === 'inactive') {
              elm.paused = true
            }

            elm.coversheets = 1
            elm.sections = 1
          })
          this.loading = false
        })
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []
      if (item.status === 'active') {
        itemsToRemove.push('activate')
      }
      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    }
  },
  async mounted () {
    this.itemType = this.itemTypeProp
    this.itemTypeId = this.$route.params.typeid

    if (this.itemType === undefined) {
      await this.getItemTypeFromDatabase()
    }

    await this.getSections()

    // Update breadcrumb
    this.breadcrumbsItems[2].text = 'Data Sections from ' + this.itemType.type
  }
})
</script>
